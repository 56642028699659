//import logo from './logo.svg';
import irclogo from './irving-running-club-logo-white.svg'
import './App.css';

import Header from './components/Header';
import Marketing from './components/Marketing';
import About from './components/About';
import Footer from './components/Footer';
import Weather from './components/Weather';
import { useKonamiCode } from "./utility/useKonamiCode";

function App() {
  const konami = useKonamiCode();
  const video = <iframe width="560" height="315" src="https://www.youtube.com/embed/uJEB_5rTfPA?si=i6-DZktI0JzEkQ5w&amp;start=934" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>;

	const logo = konami ? video : null;
  return (
    <div className="App">
      <Header />
      <useKonamiCode />
      <header className="App-header mt-5" style={{ backgroundImage: `linear-gradient(0deg, rgba(255 255 255 / 100%), rgba(18 37 74 / 70%)), url("/images/main_bck.jpeg")`, backgroundSize: 'cover' }}>
        <div className="container">
          <img src={irclogo} className="App-logo pt-2 pt-md-5" alt="logo" />
          <p className="py-5 display-5 masthead">
            <strong>Embracing exercise for a healthier lifestyle.</strong>
          </p>
          <div class="video">
         {logo}
       </div>
         <a
            className="mx-3 btn btn-darkprimary btn-lg shadow"
            href="https://www.facebook.com/groups/irvingrunningclub"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit us on Facebook for running dates and times
          </a>

          <Weather />
        </div>

      </header>
      <main>
        <div className="container mb-5 pb-5">
          <Marketing />

        </div>
        <section style={{ minHeight: 600, backgroundImage: `linear-gradient(0deg, rgba(9 20 28/ 100%), rgba(9 20 28/ 70%)), url("/images/irving-marathon-pacers.jpeg")`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="container d-flex align-items-center">
            <About />
          </div>
        </section>

        <Footer />

      </main>

    </div>
  );
}

export default App;
